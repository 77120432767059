import { Page, PageFEDSA } from 'components/layout';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';
import React, { useState, useEffect, useRef } from 'react';
import Search from '@ingka/search';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import { DataTable } from 'components/common/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'Store/store';
import TableLayout from 'components/SharedLayout/TableLayout/tableLayout';
import PendingApproval from 'pages/PendingApproval/pendingApproval';
import SaveForLater from 'pages/SaveForLater/saveForLater';
import SubmitRegistery from 'pages/SubmitRegistery/submitRegistery';
import Archived from 'pages/Archived/archived';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import './details.scss';
import GlobalSearch from 'components/GlobalSearch/globalSearch';
import { getPendingApprovalList, getRiskDetails } from 'Action/pendingApprovalAction';

const FileUpload = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
`;

export const DetailPage = (tabChange, items) => {
  const [querys, setQuery] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const [selectedStatus, setSelectedStatus] = useState('Pending');
  const submittedToEUTabRef = useRef<HTMLButtonElement>(null);
  const handleTabChange = (tabChange) => {
    setSelectedStatus(tabChange);
  };
  const handleSearch = (querys) => {
    setQuery(querys);
  };
  useEffect(() => {
    dispatch(getPendingApprovalList(selectedStatus));
  }, [selectedStatus]);

  useSelector((state: RootState) => state.fileUploadDetails);
  useSelector((state: RootState) => state.error);
  const navigate = useNavigate();

  const tabContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
  };
  const searchfunc = (e: any) => {
    console.log(e.target.value);
    // props?.onSearch(e);
  };

  const tabs = [
    <div key="detailkey" style={tabContainerStyle}>
      {/* <Tab key="tab-upload" text={'Pending Approval'} tabPanelId="tab_" />, */}
      <Tab
        key="tab-3"
        text={'Pending Approval'}
        tabPanelId="tab_3"
        onClick={() => handleTabChange('Pending')}
      />
      <Tab
        key="tab-4"
        text={'Save for Later'}
        tabPanelId="tab_4"
        onClick={() => handleTabChange('SaveForLater')}
      />
      <Tab
        key="tab-5"
        text={'Submitted to EU Registry'}
        tabPanelId="tab_5"
        onClick={() => handleTabChange('SubmittedForEUDR')}
        ref={submittedToEUTabRef}
      />
      <Tab
        key="tab-6"
        text={'Archived'}
        tabPanelId="tab_6"
        onClick={() => handleTabChange('Archived')}
      />
    </div>,
  ];

  const onClick = () => {
    navigate('/fileupload');
  };
  const tabPanels = [
    <TabPanel key="pendingApprovalPanel" tabPanelId="tab_3">
      <PendingApproval
        querys={querys}
        setQuery={setQuery}
        tabChange={handleTabChange}
        handleTabChange={handleTabChange}
        submittedToEUTabRef={submittedToEUTabRef}
        items={items}
      />
    </TabPanel>,
    <TabPanel key="saveForLaterPanel" tabPanelId="tab_4">
      <SaveForLater
        querys={querys}
        setQuery={setQuery}
        tabChange={tabChange}
        submittedToEUTabRef={submittedToEUTabRef}
      />
    </TabPanel>,
    <TabPanel key="submitRegisteryPanel" tabPanelId="tab_5">
      <SubmitRegistery querys={querys} setQuery={setQuery} tabChange={tabChange} />
    </TabPanel>,
    <TabPanel key="archivedPanel" tabPanelId="tab_6">
      <Archived />
    </TabPanel>,
  ];

  return (
    <PageFEDSA>
      <div className="tab-header">
        <div className="pending-container">
          <FileUpload style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 50 }}>
            {/* <Search
              ariaLabel="Search for product categories, product names, etc."
              clearBtnText="Clear search field"
              id="search"
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search"
              submitBtnText="Submit"
              onSearch={handleSearch}
            /> */}
            <GlobalSearch onSearch={handleSearch} />
          </FileUpload>
          <Button text="Upload Submission" size="small" onClick={onClick} />
          <Tabs
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab="tab_3"
            ariaLabel="Example Tabs Label"
          />
        </div>
      </div>
    </PageFEDSA>
  );
};
