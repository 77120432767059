import Text from '@ingka/text';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { Stack, Page } from 'components/layout';
import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import FormField from '@ingka/form-field';
import ManualEntry from 'pages/ManualEntry/manualEntry';
import Button from '@ingka/button';
import styled from 'styled-components';
import { contentDelimiterStyles } from 'components/layout';
import axios from 'axios';
import InputField from '@ingka/input-field';
import { DetailPage } from 'pages/Detail/Detail';
import { useLocation, useNavigate } from 'react-router-dom';
import SSRIcon from '@ingka/ssr-icon';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import Pill from '@ingka/pill';
import { convertFileToBase64 } from 'utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import { fileUpload, resetFileUpload } from 'Action/fileUploadAction';
import Toast from '@ingka/toast';
import Loading, { LoadingBall } from '@ingka/loading';

const FileUploadTemplate = styled.div`
  display: flex;
  flex-direction: column;
  // width: min-content;
`;

export const Fedsa = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePath, setFilePath] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [byteStreamData, setByteStreamData] = useState<any>();
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [hasNavigated, setHasNavigated] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { fileUploadData, fileUploadStatus } = useSelector(
    (state: RootState) => state.fileUploadDetails
  );
  const { error, errorMessage, errorType } = useSelector((state: RootState) => state.error);

  // const [status, setStatus] = useState<boolean>(false);
  useEffect(() => {
    setToastOpen(false);
    setHasNavigated(false);
    dispatch({
      type: 'FILE_UPLOAD',
      payload: null,
    });
    dispatch({
      type: 'FILE_UPLOAD_SUCESSFULLY',
      payload: null,
    });
    dispatch({
      type: 'RESET_ERROR',
      payload: null,
    });
  }, [window.location.href]);

  useEffect(() => {
    setToastOpen(false);
    if ((fileUploadData || errorMessage) && !hasNavigated) {
      setToastOpen(true);
      if (fileUploadData?.supplierSuccessResponse) {
        dispatch({
          type: 'RESET_ERROR',
          payload: null,
        });
        setTimeout(() => {
          setToastOpen(true);
          <Toast
            isOpen={toastOpen}
            actionClick={() => {}}
            onCloseRequest={onClose}
            ariaLabelCloseBtn="Dismiss notification"
            text={<p>Success: successfully submitteded</p>}
          />;
          navigate('/approval');
          setHasNavigated(true);
        }, 3000);
        return;
      }
      if (errorMessage) {
        dispatch({
          type: 'FILE_UPLOAD',
          payload: null,
        });
        // dispatch({
        //   type: 'FILE_UPLOAD_SUCESSFULLY',
        //   payload: null,
        // });
        dispatch({
          type: 'RESET_ERROR',
          payload: null,
        });
      }
    }
  }, [fileUploadData, errorMessage, hasNavigated]);
  // useEffect(() => {
  // if (toastOpen && fileUploadData) {
  //   const timer = setTimeout(() => {
  //     navigate('/approval');
  //     setToastOpen(false);
  //   }, 3000); // Delay of 3 seconds
  //   return () => clearTimeout(timer);
  // }
  // }, [toastOpen, fileUploadData]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(resetFileUpload());
    if (event.target.files) {
      const file = event.target.files[0];
      const fileExtension = file?.name.slice(file.name.lastIndexOf('.') + 1) || '';
      setSelectedFile(event.target.files[0]);
      const byteStream = await convertFileToBase64(file);
      setByteStreamData(byteStream);
      setByteStreamData({
        fileBytes: byteStream,
        fileType: fileExtension,
      });
      // event.target.value = null;
    }
  };

  const handleCsvTemplate = () => {
    const filePath = '/Countries_sample_data.csv';
    const a = document.createElement('a');
    a.href = filePath;
    a.download = 'Countries_sample_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleJsonTemplate = () => {
    const filePath = '/new 1.json';
    const a = document.createElement('a');
    a.href = filePath;
    a.download = 'new 1.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    setToastOpen(false);
    setHasNavigated(false);
    try {
      const response = await dispatch(fileUpload(byteStreamData));

      if (response && 'status' in response && 'data' in response) {
        const { status, data } = response;

        if ((status === 200 || status === 201) && data.supplierSuccessResponse) {
          // Success case
          setToastOpen(true);
          <Toast
            isOpen={toastOpen}
            actionClick={() => {}}
            onCloseRequest={onClose}
            ariaLabelCloseBtn="Dismiss notification"
            text={<p>Success: successfully submitted</p>}
          />;

          // if (toastOpen && fileUploadData) {
          //   const timer = setTimeout(() => {

          //     navigate('/approval');
          //     setToastOpen(false);
          //   }, 0); // Delay of 3 seconds

          //   return () => clearTimeout(timer);
          // }
        } else if ((status === 200 || status === 201) && data.supplierErrorResponse) {
          setToastOpen(true);
          dispatch({
            type: 'GENERIC_ERROR',
            payload:
              data.supplierErrorResponse.errorMessage ||
              'File upload failed due to supplier error.',
          });
        } else {
          setToastOpen(true);
          dispatch({
            type: 'GENERIC_ERROR',
            payload: 'File upload failed due to unexpected error.',
          });
        }
      }
    } catch (error) {
      setToastOpen(true);
      dispatch({
        type: 'GENERIC_ERROR',
        payload: 'File upload failed due to network or server error.',
      });
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
    // setStatus(true);
    // navigate('/detail');
  };
  const onClose = () => {
    setToastOpen(false);
  };
  const supplierDetails = (value: any) => {
    console.log(value, 'supplierDetails');
  };

  const tabs = [
    <Tab key="tab-1" text={'File Upload'} tabPanelId="tab_1" />,
    <Tab key="tab-2" text={'Manual Entry'} tabPanelId="tab_2" />,
  ];

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId="tab_1">
      <FormField>
        <input type="file" onChange={handleFileChange} accept=".json,.csv" ref={fileInputRef} />

        {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
      </FormField>
      <FileUploadTemplate style={{ width: 200 }}>
        <Pill
          iconPosition="trailing"
          size="small"
          label="Json file template"
          onClick={handleJsonTemplate}
          ssrIcon={documentAdd}
          style={{ marginBottom: '10px', borderRadius: '0px' }}
        />
        <Pill
          iconPosition="trailing"
          size="small"
          label="csv file template"
          onClick={handleCsvTemplate}
          ssrIcon={documentAdd}
          style={{ marginBottom: '10px', borderRadius: '0px' }}
        />
      </FileUploadTemplate>
      {selectedFile ? (
        <Button
          iconPosition="leading"
          key="submit"
          size="small"
          text="Submit"
          type="emphasised"
          onClick={handleSubmit}
        ></Button>
      ) : (
        <Button
          iconPosition="leading"
          key="submit"
          size="small"
          text="Submit"
          type="emphasised"
          disabled
        ></Button>
      )}
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId="tab_2">
      <>
        <ManualEntry supplierDetails={supplierDetails} />
      </>
    </TabPanel>,
  ];

  const handleTabChange = (tabId) => {
    if (tabId === 'tab_2') {
      navigate('/manualentry');
    }
  };
  return (
    <>
      {location.pathname === '/approval' ? (
        <DetailPage />
      ) : (
        <Page className="file-upload-container" style={{ marginLeft: 20 }}>
          <Tabs
            tabs={tabs}
            tabPanels={tabPanels}
            defaultActiveTab="tab_1"
            ariaLabel="Example Tabs Label"
            onTabChanged={handleTabChange}
          />
        </Page>
      )}
      {/* {(fileUploadData || errorMessage) && (
        <Toast
          text={
            <>
              <strong>{fileUploadData || errorMessage}</strong>
            </>
          }
          isOpen={toastOpen}
          actionClick={() => {}} // required if there is an action label supplied
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )} */}
      {toastOpen && (
        <Toast
          isOpen={toastOpen}
          actionClick={() => {}} // required if there is an action label supplied
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
          text={
            <>
              {fileUploadData?.supplierSuccessResponse ? (
                <p>Success: successfully submitted</p>
              ) : (
                <p>Error: error data</p>
              )}
            </>
          }
          key={fileUploadData?.supplierSuccessResponse ? 'success' : 'error'}
        />
      )}
    </>
  );
};
