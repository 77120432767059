import { SET_PRODUCTION_PLACE_ROWS, SET_PRODUCER_ROWS } from 'Action/types';

const initialState = {
  productionPlaceRows: [{ productionPlace: '', type: '', coordinates: null, area: null, id: null }],
  producerRows: [{ producerName: '', producerCountry: '' }],
};

const producerStateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PRODUCTION_PLACE_ROWS:
      return {
        ...state,
        productionPlaceRows: action.payload,
      };
    case SET_PRODUCER_ROWS:
      return {
        ...state,
        producerRows: action.payload,
      };
    default:
      return state;
  }
};

export default producerStateReducer;
