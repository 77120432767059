import React, { useState } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import InputField from '@ingka/input-field';
import RadioButtonGroup from '@ingka/radio-button-group';
import Button from '@ingka/button';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { manualValidate } from 'Action/manualValidateAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import FormField from '@ingka/form-field';
import { MANUAL_SUPPLIER_FETCH } from 'Action/types';
import './associatedDds.scss';

const AssociatedDds = (props: any) => {
  const handleDdsdata = (index: number, field: any, value: any) => {
    const newFormRows = props.oldDdsRows?.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    props.setOldDdsRows([...newFormRows]);
  };

  const dispatch = useDispatch<AppDispatch>();
  const { manualEntryData } = useSelector((state: RootState) => state.manualEntrySubmitData);
  const handleAddRow = () => {
    // Your add row logic here
  };

  const payload = {
    ddsReferenceNumber: props.oldDdsRows[0].DdsFirstProduction,
    ddsVerificationNumber: props.oldDdsRows[0].verificationNumber,
    isSupplierDDS: false,
  };
  const handleFetch = async () => {
    try {
      const response = await dispatch(manualValidate(payload, MANUAL_SUPPLIER_FETCH));
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionItem title="DDS for first production" id="productaccordion" open>
          {props.oldDdsRows?.map((row, index) => (
            <div className="dds-tabs" key={index}>
              <InputField
                style={{ marginRight: 10 }}
                iconPosition="leading"
                id={`product-name-${index}`}
                type="text"
                value={row.DdsFirstProduction}
                onChange={(e) => handleDdsdata(index, 'DdsFirstProduction', e.target.value)}
                label={index === 0 ? ' DdsFirstProduction' : ''}
              />
              <InputField
                style={{ marginRight: 10 }}
                iconPosition="leading"
                id={`article-number-${index}`}
                type="text"
                value={row.verificationNumber}
                onChange={(e) => handleDdsdata(index, 'verificationNumber', e.target.value)}
                label={
                  index === 0 ? (
                    <>
                      verificationNumber
                      {row.DdsFirstProduction && <span className="red-asterisk"> *</span>}
                    </>
                  ) : (
                    ''
                  )
                }
                required={!!row.DdsFirstProduction}
              />

              <>
                <Button onClick={handleFetch} size="xsmall">
                  Fetch
                </Button>
              </>
            </div>
          ))}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AssociatedDds;
