import { SET_PRODUCTION_PLACE_ROWS, SET_PRODUCER_ROWS } from './types';

export const setProductionPlaceRows = (rows) => ({
  type: SET_PRODUCTION_PLACE_ROWS,
  payload: rows,
});

export const setProducerRows = (rows) => ({
  type: SET_PRODUCER_ROWS,
  payload: rows,
});
