import Combobox, { MenuItem } from '@ingka/combobox';
import React, { useState, useEffect } from 'react';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import styled from 'styled-components';
import { contentDelimiterStyles, Page } from 'components/layout';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import RadioButtonGroup from '@ingka/radio-button-group';
import Products from 'pages/Products/products';
import Commodities from 'pages/Commodities/commodities';
import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { manualSubmit, resetManualEntry } from 'Action/manualSubmitAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'Store/store';
import Toast from '@ingka/toast';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/forms/dist/style.css';
import { useNavigate } from 'react-router-dom';
import { manualValidate } from 'Action/manualValidateAction';
import Accordion, { AccordionItem } from '@ingka/accordion';
import AssociatedDds from 'pages/AssociatedDDS/associatedDds';
import { masterDataManual } from 'Action/manualDropDownAction';
import { MANUAL_SUPPLIER_VALIDATE } from 'Action/types';

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
`;
const SecondDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  justify-content: space-between;
`;

const ManualEntry = (props: any) => {
  const { masterData } = useSelector((state: RootState) => state.dropDownMasterData);
  const { manualFetchData } = useSelector((state: RootState) => state.manualValidateDetails);
  const [toastOpen, setToastOpen] = useState<any>(false);
  const [isValidate, setIsValidate] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [supplierRows, setSupplierRows] = useState([
    { supplierId: '', ddsReferenceNumber: '', ddsVerificationNumber: '', ddsFilingDate: '' },
  ]);
  const [productRows, setproductRows] = useState([
    { productName: '', productIdType: '', productId: '' },
  ]);
  const [oldDdsRows, setOldDdsRows] = useState([
    { DdsFirstProduction: '', verificationNumber: '' },
  ]);
  const [commodityRows, setCommodityRows] = useState([
    {
      hsHeader: null,
      descriptionOfGoods: '',
      netWeight: null,
      numberofUnits: null,
      volume: null,
      universalUnit: '',
    },
  ]);

  // setCommodityRows([...commodityRows,manualFetchData?.data.statementInfo?.commodities[0]])
  const [productionPlaceRows, setProductionPlaceRows] = useState([
    { productionPlace: '', type: '', coordinates: null, area: null },
  ]);
  const [producerRows, setProducerRows] = useState([{ producerName: '', producerCountry: '' }]);
  const [selectedSupplier, setselectedSupplier] = useState<string>('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [validateVerification, setValidateVerification] = useState(false);
  const [isFilingImport, setIsFilingImport] = useState(false);
  const [selectedActivityType, setSelectedActivityType] = useState('');

  const handleActivityTypeChange = (event) => {
    setSelectedActivityType(event.target.value);
  };
  const dispatch = useDispatch<AppDispatch>();
  const { manualEntryData } = useSelector((state: RootState) => state.manualEntrySubmitData);
  const { error, errorMessage, errorType } = useSelector((state: RootState) => state.error);

  const navigate = useNavigate();

  const allFieldsFilled = () => {
    return (
      supplierRows.every((row) => Object.values(row).every((value) => value !== '')) &&
      productRows.every((row) => Object.values(row).every((value) => value !== '')) &&
      commodityRows.every((row) =>
        Object.values(row).every((value) => value !== null && value !== '')
      ) &&
      productionPlaceRows.every((row) =>
        Object.values(row).every((value) => value !== null && value !== '')
      ) &&
      producerRows.every((row) => Object.values(row).every((value) => value !== '')) &&
      selectedSupplier !== ''
    );
  };

  useEffect(() => {
    if (manualFetchData) {
      const transformData = ({ commodities }) => {
        return commodities.map(
          ({
            hsHeading,
            descriptionOfGoods,
            goodsMeasure: { volume, netWeight, numberofUnits, universalUnit },
          }) => ({
            hsHeader: hsHeading || null,
            descriptionOfGoods: descriptionOfGoods || '',
            netWeight: netWeight || null,
            numberofUnits: numberofUnits || null,
            volume: volume || null,
            universalUnit: universalUnit || '',
          })
        );
      };

      const transformProducerData = ({ commodities }) => {
        const producers = commodities.flatMap((commodity) => commodity.producers || []);
        return producers.map(({ producerCountry }) => ({
          producerCountry: producerCountry || null,
        }));
      };

      const transformProductionPlaceData = ({ commodities }) => {
        const producers = commodities.flatMap((commodity) => commodity.producers || []);
        return producers.flatMap(
          (producer) =>
            producer.geometryGeoJson?.features.map(
              ({
                properties: { productionPlace, area },
                geometry: { type, coordinatesString },
              }) => ({
                productionPlace: productionPlace || null,
                area: area || null,
                type: type || null,
                coordinatesString: coordinatesString || null,
              })
            ) || []
        );
      };

      const transformedData = transformData(manualFetchData);
      const transformedProducer = transformProducerData(manualFetchData);
      const transformedProductionPlace = transformProductionPlaceData(manualFetchData);

      setCommodityRows(transformedData);
      setIsAccordionOpen(true);
      setProducerRows([...transformedProducer]);
      setProductionPlaceRows([...transformedProductionPlace]);
    }
  }, [
    manualFetchData,
    setCommodityRows,
    setProducerRows,
    setProductionPlaceRows,
    setIsAccordionOpen,
  ]);

  useEffect(() => {
    setToastOpen(false);

    if (manualEntryData) {
      setToastOpen(true);
      dispatch({
        type: 'MANUAL_SUCCESS',
        payload: null,
      });
      return;
    }
    if (errorMessage && errorType === 'manualerror') {
      setToastOpen(true);
      // dispatch({
      //   type: 'MANUAL_ERROR',
      //   payload: null,
      // });
    }
  }, [manualEntryData, errorMessage]);

  useEffect(() => {
    if (toastOpen && manualEntryData) {
      const timer = setTimeout(() => {
        dispatch({
          type: 'MANUAL_SUBMIT',
          payload: null,
        });

        navigate('/approval');
        setToastOpen(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [toastOpen, manualEntryData]);

  // useEffect(() => {
  //   if (toastOpen && errorMessage) {
  //     dispatch({
  //       type: 'MANUAL_ERROR',
  //       payload: null,
  //     });
  //   }
  // }, [toastOpen, errorMessage]);

  useEffect(() => {
    dispatch(masterDataManual());
  }, []);

  // const payload = {
  //   supplierId: supplierRows[0].supplierId,
  //   productNumber: productRows[0].productId,
  //   isGTIN: true,
  //   firstProduceDDSInfo: [
  //     {
  //       ddsReferenceNumber: supplierRows[0].ddsReferenceNumber,
  //       ddsVerificationNumber: supplierRows[0].ddsVerificationNumber,
  //     },
  //   ],
  //   commodities: [
  //     {
  //       hsHeading: commodityRows[0]?.hsHeader || '',
  //       descriptionOfGoods: commodityRows[0]?.descriptionOfGoods || '',
  //       goodsMeasure: {
  //         volume: parseFloat(commodityRows[0]?.volume) || 0,
  //         netWeight: parseFloat(commodityRows[0]?.netWeight) || 0,
  //         numberofUnits: parseFloat(commodityRows[0]?.numberofUnits) || 0,
  //       },
  //       producers: [
  //         {
  //           producerName: producerRows[0]?.producerName || '',
  //           producerCountry: producerRows[0]?.producerCountry || '',
  //           geometryGeoJson: {
  //             type: 'FeatureCollection',
  //             features: [
  //               {
  //                 type: 'Feature',
  //                 properties: {
  //                   productionPlace: productionPlaceRows[0].productionPlace || '',
  //                   area: parseFloat(productionPlaceRows[0]?.area) || 0,
  //                 },
  //                 geometry: {
  //                   type: productionPlaceRows[0]?.type || 'Polygon',
  //                   coordinates: productionPlaceRows[0]?.coordinates || [],
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       ],
  //     },
  //   ],
  //   ddsReferenceNumber: supplierRows[0].ddsReferenceNumber,
  //   ddsVerificationNumber: supplierRows[0].ddsVerificationNumber,
  // };

  const payload = {
    ddsReferenceNumber: '25NL5YFZO74916',
    ddsVerificationNumber: 'ZBTHUW1E',
    isSupplierDDS: true,
  };
  const handleValidate = async () => {
    try {
      const response = await dispatch(manualValidate(payload, MANUAL_SUPPLIER_VALIDATE));
      console.log(payload, 'payload');
      console.log(response, 'response');
      if (response && response?.status === 200) {
        setIsValidate(true);
        // <Toast
        //   text={
        //     <>
        //       <strong>{'validation success'}</strong>
        //     </>
        //   }
        //   // isOpen={toastOpen}
        //   actionClick={() => { }} // required if there is an action label supplied
        //   onCloseRequest={onClose}
        //   ariaLabelCloseBtn="Dismiss notification"
        // />
        setValidationMessage('Validated successfully');
      }
    } catch (error) {
      setValidationMessage('Validation failed');
    }
  };

  const handleManualSubmit = () => {
    setToastOpen(false);

    dispatch(manualSubmit(payload));
  };
  const buttonData = [
    { label: 'Cancel', onClick: () => console.log('123') },
    { label: 'Submit', onClick: handleManualSubmit },
  ];
  const onClose = () => {
    setToastOpen(false);
  };
  const handleAddCommodityRow = () => {};
  const handleSupplierChange = (index: number, field: any, value: any, event: any) => {
    const newSupplierRows = supplierRows.map((row: any, i: any) =>
      i === index ? { ...row, [field]: value } : row
    );
    if (field === 'ddsReferenceNumber' && value.length > 14) {
      setShouldValidate(true);
    } else {
      setShouldValidate(false);
    }
    if (field === 'ddsVerificationNumber' && value.length > 14) {
      setValidateVerification(true);
    } else {
      setValidateVerification(false);
    }
    // props.supplierDetails(...newSupplierRows);
    setSupplierRows(newSupplierRows);
    setselectedSupplier(event.target.value);
  };

  const handleFilingImport = () => {
    setIsFilingImport(true);
  };

  return (
    <Page>
      <FormField>
        {supplierRows.map((row: any, index: number) => (
          <>
            <div className="supplier-tab" key={index} style={{ display: 'flex', gap: 20 }}>
              <Select
                id="supplierId"
                label="Supplier name"
                value={row.supplierId}
                onChange={(e: any) => handleSupplierChange(index, 'supplierId', e.target.value, e)}
                style={{ width: 'auto' }}
                disabled={isFilingImport}
              >
                {masterData?.supplierMaster &&
                  masterData?.supplierMaster.map((data: any) => (
                    <Option
                      key={data.supplierId}
                      value={data.supplierId}
                      name={data.supplierName}
                    />
                  ))}
              </Select>
              <FormField
                characterLimit={14}
                fieldHelper={{
                  id: 'helper-msg-id',
                  msg: 'Enter DDS Ref number',

                  type: '',
                }}
                shouldValidate={shouldValidate}
                valid={
                  row.ddsReferenceNumber &&
                  (row.ddsReferenceNumber.length >= 14 || !/^\d+$/.test(row.ddsReferenceNumber))
                    ? 'error'
                    : false
                }
                validation={{
                  id: 'error-msg-id',
                  msg: 'Enter valid Reference number',
                  type: 'error',
                }}
              >
                <InputField
                  label="DDS reference number"
                  iconPosition="leading"
                  id={`dds-ref-${index}`}
                  type="number"
                  style={{ marginRight: 10 }}
                  value={row.ddsReferenceNumber}
                  onChange={(e) =>
                    handleSupplierChange(index, 'ddsReferenceNumber', e.target.value, e)
                  }
                  readOnly={isFilingImport}
                />
              </FormField>
              <FormField
                characterLimit={13}
                fieldHelper={{
                  id: 'helper-id',
                  msg: 'Enter DDS Verification number',
                  type: '',
                }}
                shouldValidate={validateVerification}
                valid={
                  row.ddsVerificationNumber &&
                  (row.ddsVerificationNumber.length >= 13 ||
                    !/^\d+$/.test(row.ddsVerificationNumber))
                    ? 'error'
                    : false
                }
                validation={{
                  id: 'error-msg-id',
                  msg: 'dds number should be 13 digits',
                  type: 'error',
                }}
              >
                <InputField
                  style={{ marginRight: 10 }}
                  label={
                    index === 0 ? (
                      <>
                        DDs verification number
                        {row.ddsReferenceNumber && <span className="red-asterisk"> *</span>}
                      </>
                    ) : (
                      ''
                    )
                  }
                  required={!!row.ddsReferenceNumber}
                  iconPosition="leading"
                  id={`dds-ver-${index}`}
                  type="number"
                  value={row.ddsVerificationNumber}
                  onChange={(e) =>
                    handleSupplierChange(index, 'ddsVerificationNumber', e.target.value, e)
                  }
                  readOnly={isFilingImport}
                />
              </FormField>
              <Select
                id="activitytype"
                label="Activity Type"
                value={selectedActivityType}
                onChange={handleActivityTypeChange}
                style={{ width: 'auto' }}
                disabled={isFilingImport}
              >
                <Option value="0" name="export" />
                <Option value="1" name="import" />
                <Option value="2" name="trade" />
              </Select>
              <Button
                style={{ marginTop: 30 }}
                iconPosition="leading"
                key="validate"
                size="small"
                text="Validate"
                onClick={handleValidate}
                disabled={isFilingImport}
              ></Button>
              {validationMessage && <div>{validationMessage}</div>}
            </div>
            <span>OR</span>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
              <Button size="small" onClick={handleFilingImport}>
                Filing for import
              </Button>
            </div>
          </>
        ))}
        {(isValidate || isFilingImport) && (
          <>
            {/* <Products setproductRows={setproductRows} productRows={productRows} /> */}
            <AssociatedDds oldDdsRows={oldDdsRows} setOldDdsRows={setOldDdsRows} />
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleAddCommodityRow} size="xsmall">
                  Add Commodities
                </Button>
                {/* <Button onClick={handleAddCommodityRow} size="xsmall">
                    Remove commodities
                  </Button> */}
              </div>
              <Commodities
                commodityRows={commodityRows}
                setCommodityRows={setCommodityRows}
                productionPlaceRows={productionPlaceRows}
                setProductionPlaceRows={setProductionPlaceRows}
                producerRows={producerRows}
                setProducerRows={setProducerRows}
                isAccordionOpen={isAccordionOpen}
              />
            </div>
            <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
              {buttonData.map((button, index) => (
                <Button
                  key={index}
                  className="demo-pill--stacking-story"
                  iconPosition="leading"
                  text={button.label}
                  onClick={(e) => console.log('123')}
                  size="small"
                  type="emphasised"
                />
              ))}
            </div>
          </>
        )}
      </FormField>

      {manualEntryData && (
        <Toast
          text={
            <>
              <strong>{'DDS sent successfully'}</strong>
            </>
          }
          isOpen={toastOpen}
          actionClick={() => {}} // required if there is an action label supplied
          onCloseRequest={onClose}
          ariaLabelCloseBtn="Dismiss notification"
        />
      )}
    </Page>
  );
};
export default ManualEntry;
